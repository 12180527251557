body {
  /* margin-left: auto;
  margin-right: auto; */
  /* background-image:url('./images/pattern-background-desktop.svg'); */
  background-color: #d6e1ff !important;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'BasicFont';
  src: local('BasicFont'), url('./fonts/FontsFree-Net-Barclays-Premier-League.ttf') format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'SerieAFont';
  src: local('SerieAFont'), url('./fonts/Serie A Font 2020-2021.otf') format('opentype');
}

@font-face {
  font-family: 'Ligue1Font';
  src: local('Ligue1Font'), url('./fonts/Ligue 1 Font 2021-2022.otf') format('opentype');
}

@font-face {
  font-family: 'RealMadridCupFont';
  src: local('RealMadridCupFont'), url('./fonts/Real Madrid 2021-2022 Font.otf') format('opentype');
}

@font-face {
  font-family: 'LaLigaFont';
  src: local('LaLigaFont'), url('./fonts/La Liga Font 2017 2018.ttf') format('truetype');
}

@font-face {
  font-family: 'EPLFont';
  src: local('EPLFont'), url('./fonts/EPLFont.otf') format('opentype');
}

@font-face {
  font-family: 'ArsenalFont';
  src: local('ArsenalFont'), url('./fonts/Arsenal Font 2022.ttf') format('truetype');
}

@font-face {
  font-family: 'ManCityFont';
  src: local('ManCityFont'), url('./fonts/Manchester City Font 2020.otf') format('opentype');
}

@font-face {
  font-family: 'ManUtdFont';
  src: local('ManUtdFont'), url('./fonts/Manchester United Font 2020-2021.otf') format('opentype');
}

@font-face {
  font-family: 'ChelseaCupFont';
  src: local('ChelseaCupFont'), url('./fonts/Chelsea 2019-2020 Font.otf') format('opentype');
}

@font-face {
  font-family: 'LiverpoolCupFont';
  src: local('LiverpoolCupFont'), url('./fonts/Liverpool FC Font 2019-2020.otf') format('opentype');
}

@font-face {
  font-family: 'BundesligaFont';
  src: local('BundesligaFont'), url('./fonts/Bayern Munchen 2021-2022 Font.otf') format('opentype');
}

/* @font-face {
  font-family: 'NikeWorldCupFont';
  src: local('NikeWorldCupFont'), url('./fonts/Bayern Munchen 2021-2022 Font.otf') format('opentype');
}

@font-face {
  font-family: 'AdidasWorldCupFont';
  src: local('AdidasWorldCupFont'), url('./fonts/Bayern Munchen 2021-2022 Font.otf') format('opentype');
}

@font-face {
  font-family: 'BrazilFont';
  src: local('BrazilFont'), url('./fonts/Bayern Munchen 2021-2022 Font.otf') format('opentype');
}

@font-face {
  font-family: 'BrazilFont';
  src: local('AdidasWorldCupFont'), url('./fonts/Bayern Munchen 2021-2022 Font.otf') format('opentype');
} */


.login {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}
.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}
.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}
/* .login__google {
  background-color: #4285f4;
} */
.login div {
  margin-top: 7px;
}

.reset {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}
.reset__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}
.reset__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}
.reset div {
  margin-top: 7px;
}

.register {
  display: flex;
  align-items: center;
  justify-content: center;
}
.register__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}
.register__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}
.register__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}
.register__google {
  background-color: #4285f4;
}
.register div {
  margin-top: 7px;
}

.lefttext {
  justify-content: left;
}

.footer {
  position: relative;
  /* display: flex; */
  justify-content: center; 
  width: 100%;
  bottom: 0; 
  background-color: #0b58cc7a;
  color: white;
  font-weight: 600;
}

.header {
  position: relative;
  display: flex;
  /* justify-content: left;  */
}

.headerLogo {
  width: 100%;
  height: 100%;
  /* position: absolute;   */
  /* right: 0%;
  top: 0%; */
}

.headerIcons {
  /* width: 100%; */
  height: 100%;
  display: inline-flex;
  /* is this interfering with the logo position? */
  position: relative;
  top: 20%;
  /* left: 42%; */
}

img.banner {
  width: 100%;
  height: 50px;
}

.bannercontainer {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  /* justify-content: right;  */
}

img.logo {
  width: auto;
  height: 50px;
}

.center{
  margin-left: auto;
  margin-right: auto;
}

.Jerseycontainer {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* background-color: transparent; */
  position: relative;
  max-width: 100%;
  
  /* background-color: grey; */
}

.hidden {
  display: block;
}

/* Centered text in JerseyContainer*/
.centered {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 100%;
  height: auto;
  position: absolute;
  top: 15%;
  left: 0%;

}

.centeredLeagueCupNumber{
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 100%;
  height: auto;
  position: absolute;
  top: 15%;
  left: 0%;

}

.centeredBasicNumber{
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 100%;
  height: auto;
  position: absolute;
  top: 5%;
  left: 0%;

}


img.JerseyImg {
  max-width: 50%;
  height: auto;
}

img.JerseyImgThumb {
  max-width: 100px;
  height: auto;
}

.playername {
  /* font-family: "PremierLeagueFont"; */
  color: white;
  font-family: 'BasicFont';
  /* font-size: 3.5vw;
  line-height: 1vw;
  height: 3vw; */
  /* font-size: clamp(1px, 3vw, 40px);
  line-height: clamp(1px, 5vw, 40px);
  height: clamp(1px, 3vw, 40px); */
  
}

.playernumber {
  /* font-family: "PremierLeagueFont"; */
  /* vertical-align: text-top; */
  color: white;
  font-family: 'BasicFont';
  font-size: 1vw;
  font-size: clamp(10px, 16.5vw, 200px);
  line-height: clamp(10px, 15vw, 200px);
  height: clamp(10px, 16.5vw, 200px);
}

a.button{
    background-color: #0d6efd;
    border: none;
    color: white;
    padding: 15px 32px;
    margin: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
}

button {
  background-color: #0d6efd;
  border: none;
  color: white;
  padding: 15px 32px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
}

button.disabled {
  background-color: grey;
  border: none;
  color: darkgrey;
  padding: 15px 32px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  /* border-radius: 5px; */
}

button:hover {
  background-color: #0b59cc;
}

button:active {
  background-color: #0043a5;
}

table.CartItem {
  width: 100%;
}

input.SmallTextInput {
  width: 20px; /*3vw*/
}

label.notice {
  color: red;
  font-size: 10pt;
}

center {
  margin-left:auto;
  margin-right:auto;
  width:50%;
  max-width:1024px;
}



/*
https://css-tricks.com/snippets/svg/curved-text-along-path/

path {
  fill: transparent;
}

text {
  font-family: "PremierLeagueFont";
  fill: white;
  font-size: 4.0vw;
  line-height: 3vw;
  height: 4vw;
} */